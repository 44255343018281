import React from "react"
import Page from '../components/Page'
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { useStoryblokState } from "gatsby-source-storyblok"


export default function PageIndex({ data, location }) {
    const story = useStoryblokState(data.story, location)
    return (
      <Layout meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }}location={location} full_slug={story?.full_slug} lang={story?.lang} crumb={"Renta"} pathname='/'>
        <Page blok={story?.content} />
      </Layout>
    )
}

export const query = graphql`
  {
      story: storyblokEntry(full_slug: {eq: "home"}, lang: {eq: "default"}) {
      content
      field_component
      id
      full_slug
      lang
      name
      internalId
    }
  }
`